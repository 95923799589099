/** @jsx jsx */
import { jsx } from 'theme-ui';
import SectionHeader from '../Common/SectionHeader';
import Member from '../Common/Member';

const Directors = () => {
  const members = [
    {
      name: 'Tenae Shipley',
      position: 'Vice President, First Century Bank',
      description: `Tenae Shipley is Vice President of Deposit Services for First Century Bank in Knoxville. 
      Earlier she was a Vice President/ Treasury Management Sales Officer at Bank of Tennessee.
      Tenae’s extensive history in banking also includes service as a Branch Manager
      for FSG Bank from 2003 to 2016 and was the Branch Manager. She is married to Jim, Captain of the
      Major Crimes Unit for the Knox County Sheriff’s Department. They have two daughters, Faith and Grace.`,
      image: '/images/staff/TanaeShipley_pic.jpg',
    },
    {
      name: 'Mike Pasarilla',
      position: 'Retired Pastor, Christ Presbyterian Church Knoxville',
      description: `Mike Pasarilla spent more than a decade and a half in the U.S. Navy, ending his career as a Navy Lieutenant. He then spent decades serving four congregations as a pastor, including the final 13 years of his career shepherding Christ Presbyterian Church Knoxville. He retired in July 2024. Mike and his wife, Linda, have four adult children and thirteen grandchildren.`,
      image: '/images/staff/MikePasarilla.png',
    },
    {
      name: 'Steve Craft',
      position: 'Senior Pastor, Victory Baptist Church',
      description: `Steve graduated from Baptist Bible College in 1984, then moved to Cincinnati, where he
      served as an assistant pastor to two different churches for a total of 14 years. In 1998,
      God called Steve to plant Heritage Baptist Church in Lebanon, Ohio. During his 12 years of
      ministry there, God grew the church’s core group of 17 people to nearly 600 members. It
      was during this time that Steve graduated from Liberty University with two different
      master’s degrees.<br/><br/>In 2010, Steve became the senior pastor of Victory Baptist Church in Maryville,
      Tennessee. He and his wife Brenda have three children and nine grandchildren.`,
      image: '/images/staff/SteveCraft.jpg',
    },
    {
      name: 'Lisa Laugherty',
      position: 'Troubled Asset Specialist, HUD',
      description: `Lisa is a native of the Washington, DC metropolitan area but has called East Tennessee home for 20 years now. She is a wife to Brandon and together they have 3 kids through the NEDC, a 10-year-old and 8-year-old twins.  Lisa and Brandon are both active in their local church and are passionate about raising awareness of embryo donation/adoption. She works full-time as a Troubled Asset Specialist addressing at-risk properties at Housing and Urban Development.`,
      image: '/images/staff/LisaLaugherty.jpg',
    },
    {
      name: 'Donna Harrison',
      position: 'MD/ Director of Research, AAPLOG',
      description: `Dr. Donna Harrison is a physician, board-certified in obstetrics and gynecology. She is currently serving as Director of Research for the American Association of Pro-Life Obstetricians and Gynecologists, the largest non-sectarian pro-life physician organization in the world, with over 6000 members across the United States, and associate members worldwide. Under her leadership as past CEO, AAPLOG doubled membership and launched the annual Matthew Bulfin Educational Conference, amid many other accomplishments.`,
      image: '/images/staff/DonnaHarrison.jpg',
    },
    {
      name: 'Mark Taylor',
      position: 'Retired Financial Planning Advisor',
      description: `Mark is a retired Financial Planning Advisor with AIG Retirement Services. He worked with the same organization for 26 years (from 1996-2022). In this role he set up, implemented, and managed retirement plans for Hospitals, Public School Systems, Universities, Governmental Groups, For-Profit Companies, and Non-Profit Groups. He has also helped many individuals, couples, and families with full financial planning and investing, using sound Biblical financial principals. Prior to becoming a Financial Advisor, Mark worked for a Commercial Floor Coverings Company for 10 years. His last position there was in International Sales and Marketing. He and his wife Delana have two children and two grandchildren. In addition to spending time with family, and traveling, Mark enjoys fishing and being out on the water whenever possible.`,
      image: '/images/staff/MarkTaylor.png',
    }

  ];

  return (
    <section>
      <div className="text-content">
        <SectionHeader
          heading="Board of Directors"
          styles={{ pt: [null, null, null, '3rem'], h3: { color: '#666' } }}
        />
        <p sx={{ my: '2rem', fontSize: '1.25rem', color: '#666' }}>
          The National Embryo Donation Center Board of Directors oversees the NEDC. Our board
          members come from a lot of different backgrounds. They also have different interests. But
          what unites them is the desire to help couples{' '}
          <a href="https://www.embryodonation.org/adoption/">
            complete their families through embryo adoption
          </a>
          . To learn more about our board members, read their bios below.
        </p>
      </div>
      <div
        sx={{
          bg: '#61A19E',
          backgroundImage: `url('/images/background2.png')`,
        }}
      >
        <div className="text-content">
          <div
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '2rem',
              my: '3rem',
            }}
          >
            {members.map((member, index) => (
              <Member key={index} data={{ ...member, index }} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Directors;
